<template>
  <div class="position-relative mt-3">
    <spinner-component v-if="isDataLoading" item-class="table-spinner-layout"></spinner-component>
    <DynamicForm
        v-if="!isDataLoading"
        :schema="formSchemaAutoCharge"
        :initial-field-value="initForm"
        :button-data="submitAutoChargeButton"
        :is-disabled="isDisabled"
        form-class="justify-content-start"
        @submit="submit"
      >
        <template v-slot:error>
          <div class="server-error-message content mt-3" v-if="errorMessage.length">
            {{ errorMessage }}
            <button @click.prevent="errorMessage = ''" class="feather icon-x button"></button>
          </div>
        </template>
    </DynamicForm>
  </div>
</template>

<script>
import { onMounted, ref, defineAsyncComponent } from 'vue'
import DynamicForm from './DynamicForm.vue'
import { useNotificationSettingsLogic } from '@/application/composables/notificationSettingsLogic.js'
import { getSettingsPlansSubscriptions } from '@/application/services/notification-settings.js'

export default {
  name: 'AutoChargeForm',
  components: {
    DynamicForm,
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  props: {
    initForm: {
      type: Object,
    },
    planType: {
      type: String,
    },
  },
  emits: ['send-form'],
  setup(props, { emit }) {
    const isDataLoading = ref(true)
    const {
      formSchemaAutoCharge,
      getFormSchemaAutoCharge,
      submitAutoChargeButton,
      isDisabled,
      errorMessage,
    } = useNotificationSettingsLogic()

    onMounted(() => {
      getSettingsPlansSubscriptions(props.planType).then(({ data }) => {
        formSchemaAutoCharge.value = getFormSchemaAutoCharge(formSchemaAutoCharge.value, data.subscriptions, data.plans)
        isDataLoading.value = false
      })
    })

    return {
      formSchemaAutoCharge,
      submitAutoChargeButton,
      isDisabled,
      errorMessage,
      isDataLoading,
      submit: (data) => {
        emit('send-form', data)
      },
    }
  },
}
</script>
